define(['scriptOnComplete', 'app'], function(scriptOnComplete, app) {


  var BrandsAlphabetScroll = function() {

    var self = this,
      body = document.body;

    var _brandScroll = function() {
      var div = body.querySelector('[data-tabs-wrap]'),
        items = body.querySelectorAll('[data-tabs-item]');

      if (!(div && items.length)) {
        return;
      }

      var list = self._createElement('ul', [['className', 'brandsPageScroll_alphabet']]);

      for (var i = 0, len = items.length; i < len; i++) {
        var item = items[i];
        item.parentNode.removeChild(item);
        item.style.opacity = 1;
        list.appendChild(self._createElement('li', [['className', 'brandsPageScroll_alphabetItem'], ['innerHTML', item.outerHTML]]));
      }
      div.appendChild(list);

    };

    var _createElement = function(type, props) {

      props = props || [];
      var element = document.createElement(type);
      var reducer = function(acc, value) {
        acc[value[0]] = value[1];
        return acc;
      };

      return props.reduce(reducer, element);
    };

    var _setUpClickEvent = function(window) {

      self.classBrandsAlphabet = body.querySelector('.brandsPageScroll_alphabet');
      self.classBrandsAlphabetOffsetTop = self.classBrandsAlphabet.getBoundingClientRect().top + window.pageYOffset;

      var header = document.querySelector('.westendHeader')
      if (header) {
        self.classBrandsAlphabetOffsetTop -= header.clientHeight;
      }
      self.classBrandsAlphabetIsAtOffsetTop = true;
      self.backToTopPanel = body.querySelector('[data-back-to-top]');

      //Handle page scrolling
      window.addEventListener('scroll', self._removeScrollFixToTopClass);
      // Handle alphabet tab and scroll to panel
      app.on('click', self._scrollToAlphabet, self.classBrandsAlphabet);

      //separation between mobile and desktop
      self._mobileBackToTop(navigator.userAgent, window, self.classBrandsAlphabetOffsetTop, self.backToTopPanel);

    };

    self._removeScrollFixToTopClass = function() {

      if (window.pageYOffset > self.classBrandsAlphabetOffsetTop &&
        self.classBrandsAlphabetIsAtOffsetTop) {

        app.element.addClass('brandsPageScroll_onScrollFixToTop', self.classBrandsAlphabet);

        self.classBrandsAlphabetIsAtOffsetTop = false;

      } else if (window.pageYOffset <= self.classBrandsAlphabetOffsetTop && !self.classBrandsAlphabetIsAtOffsetTop) {
        app.element.removeClass('brandsPageScroll_onScrollFixToTop', self.classBrandsAlphabet);
        self.classBrandsAlphabetIsAtOffsetTop = true;
      }
    };

    self._scrollToAlphabet = function(e) {
      (e.preventDefault) ? e.preventDefault() : e.returnValue = false;
      var scrollOffset = self._getHeaderOffset(); // to fix issue regarding fixed headers
      var anchor = app.element.getAttribute('href', e.target),
        letterId = body.querySelector(anchor),
        letterIdOffset = letterId.getBoundingClientRect().top + window.pageYOffset,
        scrollTo = self.classBrandsAlphabetIsAtOffsetTop ? (letterIdOffset
        - self.classBrandsAlphabet.clientHeight * 2) :
          (letterIdOffset - self.classBrandsAlphabet.clientHeight);
      scrollTo -= scrollOffset;
      app.element.scrollTo(scrollTo, 500);
    };

    self._getHeaderOffset = function() {
      var stickyHeader = document.querySelector('.header.sticky .header-body');
      stickyHeader = stickyHeader ? stickyHeader : document.querySelector('.js-sticky-header , .westendHeader');

      if (stickyHeader) return stickyHeader.clientHeight;
      else return 0;
    };

    self._mobileBackToTop = function(userAgent, window, classBrandsAlphabetOffsetTop, backToTopPanel) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) || window.outerWidth < 768) {

        var lastPanelOffset = self._getLastPanelOffSite();

        window.addEventListener('scroll', function() {
          self._positionScrollToTop(window.scrollY, classBrandsAlphabetOffsetTop, backToTopPanel, document.body.clientHeight, lastPanelOffset);
        });

        //back to scroll animate
        app.on('click', self._mobileScrollToTop, backToTopPanel);
      }
    };


    self._getLastPanelOffSite = function() {
      var panels = document.querySelectorAll('[data-panel]');
      var lastPanel = panels[panels.length - 1];
      return lastPanel.getBoundingClientRect().top + document.body.scrollTop + lastPanel.clientHeight;
    };

    self._positionScrollToTop = function(windowScrollToY, classBrandsAlphabetOffsetTop, backToTopPanel, bodyClientHeight, lastPanelOffset) {
      if (windowScrollToY >= classBrandsAlphabetOffsetTop) {
        backToTopPanel.style.display = 'block';
      } else if (windowScrollToY <= classBrandsAlphabetOffsetTop) {
        backToTopPanel.style.display = 'none';
      }
      //back to Top
      if (windowScrollToY + bodyClientHeight <= (lastPanelOffset)) {
        app.element.addClass('brandsPageScroll_backToTopPanel-scrolledToBottom', backToTopPanel);

      } else if (windowScrollToY + bodyClientHeight >= (lastPanelOffset)) {
        app.element.removeClass('brandsPageScroll_backToTopPanel-scrolledToBottom', backToTopPanel);
      }
    };

    self._mobileScrollToTop = function(e) {
      (e.preventDefault) ? e.preventDefault() : e.returnValue = false;
      app.element.scrollTo(1, 500);
    };

    var init = function() {
      self._brandScroll();
      if (body.querySelectorAll('.brandsPageScroll_alphabet').length) {
        self._setUpClickEvent(window);
      }
      scriptOnComplete.write(self.name);
    };

    self.init = init;
    self._createElement = _createElement;
    self._brandScroll = _brandScroll;
    self._setUpClickEvent = _setUpClickEvent;
    self.name = 'brandsAlphabetScroll';
  };

  var brandsAlphabetScroll = new BrandsAlphabetScroll();
  brandsAlphabetScroll.init();
  return brandsAlphabetScroll;
});
